header {
    background-color: var(--chakra-colors-chakra-body-bg);
}

.is-second-puzzle {
    position: absolute;
    z-index: 100;
}

.is-intro-or-end {
    border-bottom: 0 !important;
}

.is-intro-or-end h1, .is-intro-or-end .infoButton {
    visibility: hidden;
}

.is-loading {
    display: none !important;
}