body {
  margin: 0;
  font-family: "Spacemono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #ffc300 !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Spacemono";
  src: url(./assets/fonts/SpaceMono-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "CockAndBalls";
  src: url(./assets/fonts/Imperial.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  font-display: swap;
}

@font-face {
  font-family: "CockAndBalls2";
  src: url(./assets/fonts/Cheltenham.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  font-display: swap;
}

@font-face {
  font-family: "CockAndBalls3";
  src: url(./assets/fonts/BlinkMacSystemFont-Regular.woff) format("woff");
  font-display: swap;
}

.shake {
  animation-name: tilt-shaking;
  animation-duration: 0.1s;
  animation-delay: 0.2s;
  animation-iteration-count: 5;
}

@keyframes shake-horizontal {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-1%);
  }

  50% {
    transform: translateX(1%);
  }

  75% {
    transform: translateX(-1%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: "yellow";
  z-index: 200;
}

.noClick {
  pointer-events: none;
}
